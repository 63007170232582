import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { UserProfile } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const getUserProfile = async (): Promise<UserProfile> => {
  try {
    const res = await axiosClientInstance.get('/Profile/GetProfile', {
      baseURL: FUSION_API.apiMicroserviceUserSecurity,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
};

const useGetMyProfileQuery = () => {
  return useQuery({
    queryKey: ['GetMyProfile'],
    queryFn: () => getUserProfile(),
  });
};

export default useGetMyProfileQuery;
