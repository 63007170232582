'use client';

import React from 'react';
import { Button } from '@/components/atom/button';
import { usePathname, useRouter } from 'next/navigation';
import { Plus } from 'lucide-react';
import { cn } from '@/lib/utils';
import useAppStore from '@/hooks/use-app-store';

// add your page url here if needed.
const HIDDEN_PAGES = [''];

const CreateButton = ({
  isAuthenticated,
  className,
}: {
  isAuthenticated: boolean;
  className?: string;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const { showCreateButton } = useAppStore((state) => state);

  if (!showCreateButton || HIDDEN_PAGES.includes(pathname)) return null;

  return (
    <Button
      className={cn('w-full', className)}
      onClick={() => {
        if (isAuthenticated) {
          router.push(`${pathname}?ref=create`);
          // TODO: Show Create Post/Community Modal
        } else {
          router.push(`${pathname}?ref=signin`);
        }
      }}
    >
      <Plus className="mr-0 md:mr-2" /> <span className="hidden md:inline-block">Create</span>
    </Button>
  );
};

export default CreateButton;
