import React from 'react';
import { Button } from '../atom/button';
import { cn } from '@/lib/utils';
import Avatar from '@/components/atom/avatar';
import Link from 'next/link';
import { NotificationTypeEnum } from '@/utils/enums';
import { routePaths } from '@/routes/routePaths';
import useApproveOrRejectCommunityMutation from '@/hooks/react-query/communities/mutations/useApproveOrRejectCommunityMutation';
import useToggleReadStatusNotification from '@/hooks/react-query/notifications/mutations/useToggleReadStatusNotification';
import { useToast } from '@/hooks/use-toast';
import { Loader2 } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';

const formatNotificationExtraData = (data: any) => {
  const result: any = {};
  if (data && data !== null) {
    const parsedInfo = JSON.parse(data);
    if (parsedInfo === null) {
      return result;
    }
    const details = parsedInfo.replace(/\s\/+$/g, '').split(',');
    details.forEach((info: string) => {
      const [key, value] = info.split(/:(.*)/s);
      if (key && value) {
        result[key.replace(/[^a-zA-Z]/gi, '').trim()] = value
          .replace(/\s\/+$/g, '')
          .replace(/['"]+/g, '')
          .trim();
      }
    });
  }
  return result;
};
const links: (string | null)[] = [
  'unknown', // 0
  'unknown', // 1
  routePaths.postDetail, // 2
  'wallet', // 3
  routePaths.postDetail, // 4
  routePaths.shopDetail, // 5
  routePaths.shopDetail, // 6
  routePaths.postDetail, // 7
  routePaths.postDetail, // 8
  routePaths.shopDetail, // 9
  routePaths.communityDetail, // 10
  null, // 11
  null, // 12
  null, // 13
  routePaths.communityDetail, // 14
  routePaths.shopDetail, // 15
  routePaths.communityDetail, // 16
  routePaths.communityDetail, // 17
  routePaths.communityDetail, // 18
  routePaths.communityDetail, // 19
  routePaths.communityDetail, // 20
  routePaths.postDetail, // 21
  routePaths.communityDetail, // 22
];
// Notification IDs for Template A
const notificationsWithRoutes: NotificationTypeEnum[] = [
  NotificationTypeEnum.NewPost, // 2
  NotificationTypeEnum.CommentOnComment, // 4
  NotificationTypeEnum.CommentOnEvent, // 5
  NotificationTypeEnum.NewEvent, // 6
  NotificationTypeEnum.PostLike, // 7
  NotificationTypeEnum.CommentLike, // 8
  NotificationTypeEnum.EventLike, // 9
  NotificationTypeEnum.CommunityLike, // 10
  NotificationTypeEnum.NewCommunityPending, // 14
  NotificationTypeEnum.EventSubscription, // 15
  NotificationTypeEnum.JoinPrivateCommunityApproved, // 16
  NotificationTypeEnum.JoinPrivateCommunityRejected, // 17
  NotificationTypeEnum.CommunityRejected, // 18
  NotificationTypeEnum.CommunityApproved, // 19
  NotificationTypeEnum.CommentOnPost, // 21
  NotificationTypeEnum.NewPublicCommunityMember, // 22
];

interface NotificationItemProps {
  hasBeenRead: boolean;
  senderAvatarUrl: string;
  senderFullName: string;
  title: string;
  body: string;
  extraData: string;
  notificationTypeId: number;
  notificationIdentity: number;
}

const NotificationItem = ({
  hasBeenRead,
  senderAvatarUrl,
  senderFullName,
  title,
  body,
  extraData,
  notificationTypeId,
  notificationIdentity,
}: NotificationItemProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const router = useRouter();
  const [approveRequest, setApproveRequest] = React.useState<'approve' | 'decline' | ''>('');

  const parsedData = formatNotificationExtraData(extraData);

  const basePath = links[notificationTypeId];
  const linkPath = basePath ? `${basePath}/${parsedData?.slug}` : '';

  const approveOrRejectMutation = useApproveOrRejectCommunityMutation();
  const markNotificationAsReadMutation = useToggleReadStatusNotification();
  // const deleteNotificationMutation = useDeleteNotification();

  const markNotificationAsRead = (onSuccess: () => void, onError: (error: any) => void) => {
    const notificationPayload = {
      id: notificationIdentity,
      readStatus: true,
    };
    markNotificationAsReadMutation.mutate(notificationPayload, {
      onSuccess,
      onError: (error: any) => onError(error),
    });
  };

  const handleApproveOrRejectRequest = (isApprove: boolean) => {
    const payload = {
      id: parsedData.CommunityId,
      approve: isApprove,
      requesterUsername: parsedData.UserName,
      requestId: parsedData.RequestId,
    };

    approveOrRejectMutation.mutate(payload, {
      onSuccess: (v) => {
        toast({
          variant: 'success',
          description: `Request successful`,
        });

        // OPTION 1 : MARK NOTIFICATION AS READ AFTER APPROVE OR DELETE.
        markNotificationAsRead(
          () => {
            queryClient.invalidateQueries();
          },
          (error: any) => {
            console.error('Failed to mark notification as read:', error);
          }
        );
      },
      onError: () => {
        toast({
          variant: 'destructive',
          description: `Action failed`,
        });
      },
    });
  };

  const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (hasBeenRead) {
      router.push(linkPath);
      return;
    }

    event.preventDefault();

    markNotificationAsRead(
      () => {
        router.push(linkPath);
      },
      (error: any) => {
        console.error('Failed to mark notification as read:', error);
      }
    );
  };

  React.useEffect(() => {
    if (approveRequest === '') return;
    if (approveRequest === 'approve') handleApproveOrRejectRequest(true);
    if (approveRequest === 'decline') handleApproveOrRejectRequest(false);
  }, [approveRequest]);

  // Notification for Routing to a post, comment, person, community page.
  const notificationTemplateTypeA = (
    <Link
      href={linkPath}
      onClick={handleNavigation}
      className={cn(
        'w-full px-4 md:px-6 py-4 flex gap-2',
        hasBeenRead
          ? 'bg-white border-b border-[#ede3f4]'
          : `bg-[#FAF4FF] border-l-4 border-[#7322A9]`
      )}
    >
      <Avatar
        fallbackText={senderFullName}
        src={senderAvatarUrl}
        className="shadow-img h-8 w-8 min-w-8 cursor-pointer"
      />
      <div className="w-full">
        <h4 className="font-medium text-base text-[#49404C] mb-0.5 cursor-pointer">{title}</h4>

        <p className="font-normal text-xs text-[#7F7186] mb-1.5 cursor-pointer">{body}</p>
      </div>
    </Link>
  );

  // Notification for allowing user to join a community (shows buttons to approve/decline)
  const notificationTemplateTypeB = (
    <div
      className={cn(
        'w-full px-4 md:px-6 py-4 flex gap-2',
        hasBeenRead
          ? 'bg-white border-b border-[#ede3f4]'
          : `bg-[#FAF4FF] border-l-4 border-[#7322A9]`
      )}
    >
      <Avatar
        fallbackText={senderFullName}
        src={senderAvatarUrl}
        className="shadow-img h-8 w-8 min-w-8"
      />
      <div className="w-full">
        <h4 className="font-medium text-base text-[#49404C] mb-0.5">{title}</h4>

        <p className="font-normal text-xs text-[#7F7186] mb-1">{body}</p>

        {hasBeenRead ? (
          <div className="flex gap-2 items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0003 18.3334C14.5837 18.3334 18.3337 14.5834 18.3337 10.0001C18.3337 5.41675 14.5837 1.66675 10.0003 1.66675C5.41699 1.66675 1.66699 5.41675 1.66699 10.0001C1.66699 14.5834 5.41699 18.3334 10.0003 18.3334Z"
                stroke="#027A48"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.45801 9.99993L8.81634 12.3583L13.5413 7.6416"
                stroke="#027A48"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p className="font-semibold text-base text-[#027A48]">Action Taken</p>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <Button
              className="px-8"
              onClick={() => {
                setApproveRequest('approve');
              }}
            >
              {approveOrRejectMutation.isPending && approveRequest === 'approve' ? (
                <Loader2 className="animate-spin" />
              ) : (
                'Accept'
              )}
            </Button>
            <Button
              variant={'ghost'}
              onClick={() => {
                setApproveRequest('decline');
              }}
              className="bg-white px-8 border border-gray-brand3"
            >
              {approveOrRejectMutation.isPending && approveRequest === 'decline' ? (
                <Loader2 className="animate-spin" />
              ) : (
                'Reject'
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  // Notification for Allowing user to just view (no linkin, no action buttons)
  const notificationTemplateTypeC = (
    <div
      className={cn(
        'w-full px-4 md:px-6 py-4 flex gap-2',
        hasBeenRead
          ? 'bg-white border-b border-[#ede3f4]'
          : `bg-[#FAF4FF] border-l-4 border-[#7322A9]`
      )}
    >
      <Avatar
        fallbackText={senderFullName}
        src={senderAvatarUrl}
        className="shadow-img h-8 w-8 min-w-8"
      />
      <div className="w-full">
        <h4 className="font-medium text-base text-[#49404C] mb-0.5">{title}</h4>

        <p className="font-normal text-xs text-[#7F7186] mb-1.5">{body}</p>
      </div>
    </div>
  );

  return (
    <>
      {notificationTypeId === NotificationTypeEnum.NewCommunityMember
        ? //Notification showing buttons to allow users join a private community
          notificationTemplateTypeB
        : notificationsWithRoutes.includes(notificationTypeId)
        ? // Anyone included, display the linking notification
          notificationTemplateTypeA
        : // Anyone not included, display the no action, no linking notification
          notificationTemplateTypeC}
    </>
  );
};

export default NotificationItem;
