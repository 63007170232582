'use client';
import { ChevronDown } from 'lucide-react';
import Link from 'next/link';
import React, { useState } from 'react';
import {
  CommunitiesIcon,
  ConnectionsIcon,
  EventIcon,
  ExploreIcon,
  FeedIcon,
  PeopleIcon,
  SettingsIcon,
  WalletIcon,
} from './sidebarIcons';
import { cn } from '@/lib/utils';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import { PRIVATE_NAVBAR_LIST, REDIRECT_PARAMS } from '@/constants';
import DownloadIcon from '@/components/ui/icons/download-icon';
import { buttonVariants } from '@/components/atom/button';
import CreateButton from './CreateButton';
import Image from 'next/image';

const isActivePath = (
  paths: string[],
  pathname: string,
  searchParams?: ReadonlyURLSearchParams
): boolean => {
  if (
    (pathname === '/' && pathname === paths[0]) ||
    (pathname === `/` &&
      `${pathname}?${REDIRECT_PARAMS.from}=${REDIRECT_PARAMS.app}` === paths[0] &&
      searchParams?.get(REDIRECT_PARAMS.from)) === REDIRECT_PARAMS.app
  )
    return true;
  return paths.some((path) => path !== '/' && pathname.includes(path));
};

const Sidebar = ({
  className,
  asChild,
  hideCreate,
  onItemClick,
  isAuthenticated = false,
}: {
  className?: string;
  asChild?: boolean;
  hideCreate?: boolean;
  onItemClick?: () => void;
  isAuthenticated?: boolean;
}) => {
  const data = [
    {
      slug: 'group-1',
      items: [
        {
          slug: 'feed',
          name: 'Feed',
          path: '/feeds',
          icon: FeedIcon,
          status: 'available',
          requireAuth: true,
          children: null,
        },
        {
          slug: 'communities',
          name: 'Communities',
          path: '/communities',
          icon: CommunitiesIcon,
          status: 'available',
          requireAuth: true,
          children: null,
        },
        {
          slug: 'connections',
          name: 'Connections',
          path: '/connections',
          icon: ConnectionsIcon,
          status: 'available',
          requireAuth: true,
          children: null,
        },
        {
          slug: 'my-events',
          name: 'My Events',
          path: '/my-events',
          icon: EventIcon,
          status: 'available',
          requireAuth: true,
          children: null,
        },

        // TODO: ADD CHAT ITEM
        // {
        //   slug: 'chat',
        //   name: 'Chat',
        //   path: '#',
        //   icon: ChatIcon,
        //   status: 'coming-soon',
        //   requireAuth: true,
        //   children: null,
        // },
      ],
    },
    {
      slug: 'group-2',
      items: [
        {
          slug: 'explore',
          name: 'Explore',
          path: '#',
          icon: ExploreIcon,
          children: [
            {
              slug: 'posts',
              name: 'Posts',
              path: !isAuthenticated ? '/' : `/?${REDIRECT_PARAMS.from}=${REDIRECT_PARAMS.app}`,
              icon: null,
              status: 'available',
              requireAuth: false,
              children: null,
            },
            {
              slug: 'public-communities',
              name: 'Communities',
              path: '/explore/public-communities',
              icon: null,
              status: 'available',
              requireAuth: false,
              children: null,
            },
            {
              slug: 'shop',
              name: 'Shop',
              path: '/explore/shop',
              icon: null,
              status: 'available',
              requireAuth: false,
              children: null,
            },
          ],
        },
        {
          slug: 'people',
          name: 'People',
          path: '/people',
          icon: PeopleIcon,
          status: 'available',
          requireAuth: false,
          children: null,
        },
      ],
    },
    {
      slug: 'group-3',
      items: [
        {
          slug: 'wallet',
          name: 'Wallet',
          path: '/wallet',
          icon: WalletIcon,
          status: 'coming-soon',
          children: null,
          requireAuth: true,
        },
      ],
    },
    {
      slug: 'group-4',
      items: [
        // TODO: ADD ANALYTCS ITEM
        // {
        //   slug: 'analytics',
        //   name: 'Analytics',
        //   path: '#',
        //   icon: AnalyticsIcon,
        //   status: 'coming-soon',
        //   children: null,
        //   requireAuth: true,
        // },
        {
          slug: 'settings',
          name: 'Settings',
          path: '/settings',
          icon: SettingsIcon,
          status: 'coming-soon',
          children: null,
          requireAuth: true,
        },
      ],
    },
  ];

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [showQrCode, setShowQrCode] = useState(false);

  return (
    <aside
      className={cn(
        'w-[200px] flex flex-col pb-6 md:pb-0 gap-4 sticky h-full',
        className,
        asChild ? 'relative' : 'top-24 left-0'
      )}
    >
      {!hideCreate && <CreateButton isAuthenticated={isAuthenticated} />}

      <section
        className={cn(
          'relative z-40 w-full max-h-[calc(100vh-4rem)] md:h-full overflow-y-auto transition-transform -translate-x-full rounded-b-lg sm:translate-x-0 text-sm font-normal text-gray-brand7',
          asChild && 'translate-x-0'
        )}
        aria-label="Sidenav"
      >
        <div className="overflow-y-auto py-5 px-3 h-full bg-white rounded-lg">
          {data.map((group, idx) => (
            <ul
              className={cn('space-y-2', idx > 0 && 'pt-5 mt-5 border-t border-gray-200')}
              key={group.slug}
            >
              {group.items.map(({ icon: Icon, ...groupItem }) =>
                groupItem.children && groupItem.children.length > 0 ? (
                  <li
                    key={groupItem.slug}
                    className="group  transition-all duration-500 ease-in-out"
                  >
                    <button
                      type="button"
                      className="flex group items-center p-2 w-full rounded-lg transition-all duration-300 hover:border-l-2 hover:bg-pink-brand-muted/80 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium"
                      aria-controls="dropdown-pages"
                      data-collapse-toggle="dropdown-pages"
                    >
                      {Icon && <Icon />}
                      <span className="flex-1 ml-3 text-left whitespace-nowrap">
                        {groupItem.name}
                      </span>
                      <ChevronDown size={20} strokeWidth={1} className="group-hover:rotate-180" />
                    </button>
                    <ul
                      id="dropdown-pages"
                      className={cn(
                        'hidden py-2 space-y-2 group-hover:block bg-gray-brand4 transition-all duration-500 ease-in-out',
                        isActivePath(
                          groupItem.children.map((child) => child.path),
                          pathname,
                          searchParams
                        ) && 'block'
                      )}
                    >
                      {groupItem.children.map((child) => (
                        <li key={child.slug}>
                          <Link
                            onClick={() => onItemClick?.()}
                            href={
                              child.requireAuth
                                ? isAuthenticated
                                  ? child.path
                                  : `${pathname}?ref=signin&callback=${child.path}`
                                : child.path
                            }
                            className={cn(
                              'flex items-center p-2 pl-11 w-full  rounded-lg transition-all duration-300 group hover:border-l-2 hover:bg-pink-brand-muted/80 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium',
                              isActivePath([child.path], pathname, searchParams) &&
                                'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium'
                            )}
                          >
                            {child.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={groupItem.slug}>
                    <Link
                      onClick={() => onItemClick?.()}
                      href={
                        groupItem.requireAuth
                          ? isAuthenticated
                            ? groupItem.path
                            : `${pathname}?ref=signin&callback=${groupItem.path}`
                          : groupItem.path
                      }
                      className={cn(
                        'flex items-center p-2 relative  rounded-lg  hover:bg-pink-brand-muted/80 hover:border-l-2 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium group',
                        isActivePath([groupItem.path], pathname, searchParams) &&
                          'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium border-l-2 '
                      )}
                    >
                      {Icon && <Icon />}
                      <span className="ml-3">{groupItem.name}</span>

                      {groupItem.status === 'new' && (
                        <span className="text-[8px] flex items-center justify-center font-medium h-3 bg-purple-brand w-fit text-end leading-1 py-0 rounded-sm text-white px-0.5 absolute right-0">
                          New
                        </span>
                      )}
                      {/* {groupItem.status === 'coming-soon' && (
                        <span className="text-[8px] flex items-center justify-center font-medium h-3 bg-purple-brand w-fit text-end leading-1 py-2 rounded-sm text-white px-3 absolute right-0">
                          Coming
                        </span>
                      )} */}
                    </Link>
                  </li>
                )
              )}
            </ul>
          ))}
          <div className="h-[1px] w-full bg-gray-brand10 my-3 block md:hidden" />
          {asChild && (
            <ul>
              {PRIVATE_NAVBAR_LIST.map((item) =>
                item.children && item.children.length > 0 ? (
                  <li key={item.name} className="group  transition-all duration-500 ease-in-out">
                    <button
                      type="button"
                      className="flex group items-center p-2 w-full rounded-lg transition-all duration-300 hover:border-l-2 hover:bg-pink-brand-muted/80 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium"
                      aria-controls="dropdown-pages"
                      data-collapse-toggle="dropdown-pages"
                    >
                      <span className="flex-1 text-left whitespace-nowrap">{item.name}</span>
                      <ChevronDown size={20} strokeWidth={1} className="group-hover:rotate-180" />
                    </button>
                    <ul
                      id="dropdown-pages"
                      className={cn(
                        'hidden py-2 space-y-2 group-hover:block bg-gray-brand4 transition-all duration-500 ease-in-out',
                        isActivePath(
                          item.children.map((child) => child.path),
                          pathname,
                          searchParams
                        ) && 'block'
                      )}
                    >
                      {item.children.map((child) => (
                        <li key={child.name}>
                          {child.external ? (
                            <a
                              href={child.path}
                              target="_blank"
                              className={cn(
                                'flex items-center p-2 relative  rounded-lg  hover:bg-pink-brand-muted/80 hover:border-l-2 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium group',
                                isActivePath([child.path], pathname, searchParams) &&
                                  'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium border-l-2 '
                              )}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <Link
                              onClick={() => onItemClick?.()}
                              href={child.path}
                              className={cn(
                                'flex items-center p-2 pl-11 w-full  rounded-lg transition-all duration-300 group hover:border-l-2 hover:bg-pink-brand-muted/80 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium',
                                isActivePath([child.path], pathname, searchParams) &&
                                  'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium'
                              )}
                            >
                              {child.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={item.name} className="my-2">
                    {item.external ? (
                      <a
                        href={item.path}
                        target="_blank"
                        className={cn(
                          'flex items-center p-2 relative  rounded-lg  hover:bg-pink-brand-muted/80 hover:border-l-2 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium group',
                          isActivePath([item.path], pathname, searchParams) &&
                            'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium border-l-2 '
                        )}
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        href={item.path}
                        className={cn(
                          'flex items-center p-2 relative  rounded-lg  hover:bg-pink-brand-muted/80 hover:border-l-2 hover:border-purple-brand7 hover:text-purple-brand3 hover:font-medium group',
                          isActivePath([item.path], pathname, searchParams) &&
                            'bg-pink-brand-muted/80 border-purple-brand7 text-purple-brand3 font-medium border-l-2 '
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                )
              )}
            </ul>
          )}
          <div className="h-10"></div>
        </div>

        <div
          className="absolute bottom-0 px-4 w-full bg-white py-4 rounded-b-lg"
          onMouseEnter={() => setShowQrCode(true)}
          onMouseLeave={() => setShowQrCode(false)}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.cone.fusion"
            target="_blank"
            className={cn(
              buttonVariants({ variant: 'outline' }),
              ' bg-white font-medium w-full px-0 text-purple-brand gap-2 items-center'
            )}
          >
            <DownloadIcon /> Download the App
          </a>

          {showQrCode && (
            <div
              className="absolute bottom-16 left-0 w-full bg-none z-[1] flex justify-center items-center"
              style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Image
                src={'/assets/mobile_app_qr_code.jpg'}
                width={400}
                height={400}
                alt="mobile app qr code"
                className="w-32 h-auto"
              />
            </div>
          )}
        </div>
      </section>
    </aside>
  );
};

export default Sidebar;
